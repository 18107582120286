import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import Dash from "./pages/Dash";
import AboutUs from "./pages/AboutUs";
import Settings from "./pages/Settings";
import Devices from "./pages/Devices.mjs";
import store from "./store/store.mjs";
import Projects from "./pages/Projects.mjs";
import Error from "./pages/Error.mjs";
import Events from "./pages/Events.mjs";
import Alerts from "./pages/Alerts.mjs";
import Rules from "./pages/Rules.mjs";

// Clear the existing HTML content
document.body.innerHTML = '<main id="app"></main>';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

//Define routes
const router = createBrowserRouter([
  {
    element: <Dash instance={msalInstance} />,
    children: [
      {
        path: "/",
        index: true,
        element: <Projects />,
        errorElement: <Error />,
      },
      {
        path: "rules",
        element: <Rules />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "about",
        element: <AboutUs />,
      },
      {
        path: "projects/:orgId/devices/:deviceId",
        element: <Devices />,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "settings",
    element: <Settings instance={msalInstance} />,
    errorElement: <Error />,
  },
]);

createRoot(document.getElementById("app")).render(
  <StoreProvider store={store}>
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  </StoreProvider>,
);
