import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import _ from "lodash";

import NoData from "../components/NoData.mjs";
import IoTAlert from "../store/events/IoTAlert.mjs";

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  {
    field: "startedAt",
    headerName: "Occured at",
    width: 180,
    valueFormatter: (value, row) => {
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "endedAt",
    headerName: "Ended at",
    width: 180,
    valueFormatter: (value, row) => {
      if (AlertEvent.ONGOING_ALERT === value) {
        return AlertEvent.ONGOING_ALERT;
      }
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "ruleName",
    headerName: "Rule Name",
    width: 200,
    editable: false,
  },
  {
    field: "alertCause",
    headerName: "Cause",
    width: 150,
    editable: false,
  },
  {
    field: "alertDuration",
    headerName: "Duration",
    width: 150,
    editable: false,
  },
  /* {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  }, */
];

//Internal AlertEvent class to represent the summarized alert events.
class AlertEvent {
  static ONGOING_ALERT = "Still ongoing";
  constructor(
    id,
    startedAt,
    endedAt,
    ruleName,
    alertValue,
    alertCause,
    alertDuration,
  ) {
    this.id = id;
    this.startedAt = startedAt;
    this.endedAt =
      IoTAlert.AlertType.ENDED === alertValue
        ? endedAt
        : AlertEvent.ONGOING_ALERT;
    this.ruleName = ruleName;
    this.alertCause = alertCause;
    this.alertDuration =
      IoTAlert.AlertType.ENDED === alertValue
        ? alertDuration
        : AlertEvent.ONGOING_ALERT;
  }
}

/**
 * Alerts are sent as two separate events, one for alert started and one for alert ended in chronological order.
 * Combines the alert started and alert ended events into a single row of AlertEvent for more user friendly comprehension.
 * @param {Array<IoTAlert>} rawAlertEvents
 * @returns {Array<AlertEvent>}
 */
function summarizeAlerts(rawAlertEvents) {
  const alertEvents = [];
  let index = 1;
  const byAlertStatus = _.groupBy(rawAlertEvents, "messageValue");
  const sortedStartedAlerts = _.orderBy(
    byAlertStatus[IoTAlert.AlertType.STARTED],
    ["enqueuedTime"],
    ["desc"],
  );
  const sortedEndedAlerts = _.orderBy(
    byAlertStatus[IoTAlert.AlertType.ENDED],
    ["enqueuedTime"],
    ["desc"],
  );

  //Loop through all alert started events and append alert ended events to the corresponding alert start event.
  //If an "alert started" event has no "alert ended" event, it means that it is still ongoing alert.
  for (const alertStart of sortedStartedAlerts) {
    const alertEnd = sortedEndedAlerts.find(
      (alertEnd) =>
        alertEnd.ruleId === alertStart.ruleId &&
        alertEnd.enqueuedTime > alertStart.enqueuedTime,
    );

    alertEvents.push(
      new AlertEvent(
        index++,
        alertStart.enqueuedTime,
        alertEnd?.enqueuedTime,
        alertStart.ruleName,
        alertEnd?.messageValue,
        alertStart.cause,
        alertEnd?.alertDuration,
      ),
    );
  }

  //return the summarized AlertEvents
  return alertEvents;
}

export default function Alerts() {
  const getAlerts = useStoreActions((actions) => actions.alerts.getAlerts);
  const alertsArr = useStoreState((state) => state.alerts.data);
  const [rows, setRows] = React.useState([]);

  //default time window is 1 day
  const nowUTC = DateTime.now().toUTC();
  const from = nowUTC.minus({ days: 3 }).toISO();
  const to = nowUTC.toISO();
  const [timeWindow, setTimeWindow] = React.useState({ from, to });

  useEffect(() => {
    getAlerts(timeWindow);
  }, []);

  useEffect(() => {
    if (alertsArr?.length >= 0) {
      //prepare rows
      setRows(summarizeAlerts(alertsArr));
    }
  }, [alertsArr]);

  return (
    <Box sx={{ marginTop: "16px", width: "100%", height: "90vh" }}>
      {rows.length === 0 ? (
        <NoData />
      ) : (
        <DataGrid
          sx={{
            borderRadius: "10px",
            borderColor: "#2b600033",
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#00000012",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              background: "#2b600033",
              border: "#2b600033",
            },
            "& .MuiDataGrid-columnHeaders>div": {
              background: "#2b600033!important",
            },
          }}
          disableColumnMenu
          /* disableColumnFilter */
          disableColumnSelector
          disableColumnSorting
          columnHeaderHeight={36}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15]}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      )}
    </Box>
  );
}
