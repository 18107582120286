import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStoreActions, useStoreState } from "easy-peasy";
import { DateTime } from "luxon";
import { experimentalStyled as styled } from "@mui/material/styles";

import Device from "../components/Device.mjs";
import DateWindow from "../components/DateWindow.mjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RuleIcon from "@mui/icons-material/Rule";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const DeviceButton = styled(Button)(({ theme }) => ({
  minWidth: 110,
  backgroundColor: "#0442960D",
  color: "#000000CC",
  fontSize: "16px",
  "&:hover": { background: "#005BD4", color: "white" },
}));

function noDataReceived() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <InfoOutlinedIcon />
      <Typography variant="string" component="p" sx={{ marginLeft: "5px" }}>
        No data has been received for this device yet.
      </Typography>
    </Box>
  );
}

function waterChart(chartType, chartsData) {
  return (
    <LineChart
      key={chartType}
      xAxis={[
        {
          id: "temporalAxis",
          scaleType: "time",
          data: chartsData.get("sampleDate"),
          valueFormatter: (date, context) => {
            //WARNING: For tooltip date is of type luxon DateTime, but for axis tick it's a JS Date!
            return context.location === "tooltip"
              ? `${date.toLocaleString({ month: "short", day: "numeric" })} ${date.toLocaleString(DateTime.TIME_24_SIMPLE)}`
              : date.toLocaleTimeString("en-US", {
                  timeStyle: "short",
                  hour12: false,
                });
          },
        },
      ]}
      series={[
        {
          data: chartsData.get(chartType),
          label: chartsData.get("legend")[chartType],
          type: "line",
          color: "#FFAC1C",
        },
      ]}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        border: 1,
        borderColor: "common.grey25",
        paddingBottom: 0,
        borderRadius: "10px",
        marginBottom: "10px",
      }}
      width={330}
      height={300}
    />
  );
}

function renderCharts(chartsData) {
  const charts = [];
  let result = noDataReceived();
  //let result = <Error error={new Error("400")} />;

  const chartsOrder = [
    "ph",
    "turb",
    "temp",
    "dischflow",
    "dischvol",
    "recirflow",
    "recirvol",
    "tempc",
    "rain",
  ];

  for (const waterProperty of chartsOrder) {
    //Only add charts that have data
    if (chartsData.has(waterProperty)) {
      charts.push(waterChart(waterProperty, chartsData));
    }
  }

  if (charts.length) {
    result = (
      <Box
        sx={{
          m: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {charts}
      </Box>
    );
  }

  return result;
}

export default function Devices() {
  const { deviceId, orgId } = useParams();
  const [syncNeeded, setSyncNeeded] = useState(true);
  const [charts, setCharts] = useState(null);

  const timeWindow = useStoreState((state) => state.telemetry.timeWindow);
  const pollingId = useStoreState((state) => state.telemetry.polling);
  const pollingHierarchyId = useStoreState(
    (state) => state.orgHierarchy.polling,
  );

  const pollTelemerty = useStoreActions(
    (actions) => actions.telemetry.pollTelemetryForTime,
  );
  const pollHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.pollHierarchyForTime,
  );

  const setPolling = useStoreActions((actions) => actions.telemetry.setPolling);
  const setHierarchyPolling = useStoreActions(
    (actions) => actions.orgHierarchy.setPolling,
  );

  const stopPolling = useStoreActions(
    (actions) => actions.telemetry.stopPolling,
  );
  const stopHierarchyPolling = useStoreActions(
    (actions) => actions.orgHierarchy.stopPolling,
  );

  const setCurrentDeviceId = useStoreActions(
    (actions) => actions.setCurrentDeviceId,
  );

  const setCurrentProjectId = useStoreActions(
    (actions) => actions.setCurrentProjectId,
  );

  const deviceTelemetry = useStoreState((state) => state.telemetry.data);

  //onUnmount halt polling
  useEffect(
    () => () => {
      const stopPoll = async function () {
        await stopPolling();
        await stopHierarchyPolling();
      };

      stopPoll();
    },
    [],
  );

  useEffect(() => {
    const sync = async () => {
      if (pollingId > -1 && pollingHierarchyId > -1) {
        await stopPolling();
        await stopHierarchyPolling();
      }

      setSyncNeeded(true);
    };

    sync();
  }, [timeWindow]);

  useEffect(() => {
    const sync = async () => {
      setCurrentDeviceId(deviceId);
      setCurrentProjectId(orgId);

      if (pollingId === -2 && pollingHierarchyId === -2) {
        setPolling(-1);
        setHierarchyPolling(-1);
        await pollTelemerty();
        await pollHierarchy();
      }
    };

    if (syncNeeded) {
      sync();
    }

    setSyncNeeded(false);
  }, [syncNeeded]);

  useEffect(() => {
    if (deviceTelemetry?.size) {
      setCharts(renderCharts(deviceTelemetry));
    }
  }, [deviceTelemetry]);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "2em",
      }}
    >
      {!deviceTelemetry?.size ? (
        <Skeleton variant="rounded" animation="wave" width={210} height={60} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            component="header"
            sx={{
              width: "330px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Device />
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                maxWidth: 300,
                minWidth: 320,
                justifyContent: "center",
                marginTop: 1,
                marginBottom: 1,
              }}
            >
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {/* <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<AutoGraphIcon />}
                  >
                    Graphs
                  </DeviceButton> */}
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<SdCardAlertIcon />}
                    component={Link}
                    to="/alerts"
                  >
                    Alerts
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<EventAvailableIcon />}
                    component={Link}
                    to="/events"
                  >
                    Events
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<RuleIcon />}
                    component={Link}
                    to="/rules"
                  >
                    Rules
                  </DeviceButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<InsertDriveFileIcon />}
                    href="https://wqms-cwgddsaweseyb2cx.z01.azurefd.net/public-files/QualityManagement-QMP001.pdf"
                    download="QASchedule.jpg"
                    target="_blank"
                  >
                    Docs
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<FindInPageIcon />}
                    component={Link}
                    to="/audit"
                  >
                    Audit
                  </DeviceButton>
                  <DeviceButton
                    sx={{ minWidth: 100 }}
                    size="medium"
                    variant="outlined"
                    startIcon={<InsertDriveFileIcon />}
                  >
                    Download
                  </DeviceButton>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              // border: 1,
              // borderColor: "common.grey25",
              // background:"#f0f0f033",
              flexDirection: "column",
              marginTop: "1em",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <DateWindow />
            <Box
              sx={{
                "& .MuiBox-root": { margin: 0, marginTop: "10px" },
              }}
              component="article"
            >
              {charts}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
