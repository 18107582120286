import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as atlas from "azure-maps-control";
import { AZURE_MAPS_SUBSCRIPTION_KEY } from "../constants";
import "azure-maps-control/dist/atlas.min.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  List,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PlaceIcon from "@mui/icons-material/Place";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: "0",
          },
          backgroundColor: "rgba(0, 0, 0, .06)",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "45px",
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: "0",
          },
          fontWeight: "bold",
          flex: 0,
        },
      },
    },
  },
  typography: {
    deviceName: {
      fontSize: 24,
      color: "#044296",
      fontWeight: "400",
    },
    // Disable h3 variant
    h3: undefined,
  },
});

const AzureMapComponent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false); // Track if the accordion is expanded
  const [devices, setDevices] = useState([]);
  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);

  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null); // Reference to the map instance
  const [selectedDeviceId, setSelectedDeviceId] = useState(null); // Track selected device ID
  const markersRef = useRef([]); // Store references to the markers
  const listItemRefs = useRef({}); // Store refs for each list item

  const defaultPosition = [-123.002998, 49.287327];

  useEffect(() => {
    try {
      if (deviceHierarchy) {
        setLoading(false);
        const projects = deviceHierarchy.getProjects();

        if (projects.length > 0) {
          setDevices(
            projects.map((project) => ({
              id: project?.devices?.at(0)?.id,
              orgId: project?.orgId,
              name: project?.devices?.at(0)?.displayName || "Unknown Device",
              // position: [ -123.002998,  49.287327 ],
              position: project?.projectDescription?.location,
              address:
                project?.projectDescription?.address || "Unknown Address",
              status:
                project?.devices?.at(0)?.connectionState === "connected"
                  ? "Connected"
                  : "Disconnected",
              valvePosition: project?.devices?.at(0)?.valvePosition
                ? "Open"
                : "Closed",
            })),
          );
        } else {
          setError("No devices found");
        }

        // setDevices(
        // [
        //     {
        //       id: 1,
        //       name: 'displayName 1',
        //       position: [ -123.002998,  49.287327 ],
        //       address:'123 Mulholland dr',
        //     },
        //     {
        //       id: 2,
        //       name: 'displayName 2',
        //       position: [ -123.102998,  49.387327 ],
        //       address:'456 Francis dr',
        //     },
        //     {
        //       id: 3,
        //       name: 'displayName 3',
        //       position: [  -122.917099, 49.210116 ],
        //       address:'789 South dr',
        //     },
        //     {
        //       id: 4,
        //       name: 'displayName 4',
        //       position: [ -122.62, 49.18 ],
        //       address:'908 Abra dr',
        //     },
        //     {
        //       id: 5,
        //       name: 'displayName 5',
        //       position: [ -122.52, 49.090116 ],
        //       address:'12 Seem dr',
        //     },
        //     {
        //       id: 6,
        //       name: 'displayName 6',
        //       position: [ -122.95, 49.15 ],
        //       address:'1256 Wood dr',
        //     },
        // ]);
      } else {
        loadDeviceHierarchy().catch(() => {
          setError("Failed to load devices");
          setLoading(false);
        });
      }
    } catch (err) {
      setError("An unexpected error occurred");
      setLoading(false);
    }
  }, [deviceHierarchy]);

  const mapOptions = useMemo(
    () => ({
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: AZURE_MAPS_SUBSCRIPTION_KEY,
      },
      center: devices.length === 1 ? devices[0].position : defaultPosition,
      zoom: 10,
      view: "Auto",
    }),
    [devices],
  );

  // Set map bounds to include all devices, or zoom out to show the world
  const mapBoundBox = (map) => {
    const validDevices = devices.filter(
      (device) => device.position?.length === 2,
    ); // Filter out invalid positions

    if (validDevices.length > 1) {
      const boundingBox = atlas.data.BoundingBox.fromPositions(
        validDevices.map((device) => device.position),
      );
      map.setCamera({ bounds: boundingBox, padding: 20 });
    } else if (validDevices.length === 1) {
      map.setCamera({ center: validDevices[0].position, zoom: 10 }); // Set zoom to 10 for one valid device
    } else {
      map.setCamera({ center: defaultPosition, zoom: 1 }); // Zoom out to show the whole world
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize the map instance
    const map = new atlas.Map(mapRef.current, mapOptions);
    mapInstanceRef.current = map; // Store the map instance

    map.controls.add(
      [
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
        new atlas.control.StyleControl(),
      ],
      { position: "top-right" },
    );

    mapBoundBox(map);

    map.events.add("ready", () => {
      // Clear previous markers
      markersRef.current.forEach(({ marker }) => {
        map.markers.remove(marker);
      });
      markersRef.current = []; // Reset markers ref

      devices.forEach((device, index) => {
        const marker = new atlas.HtmlMarker({
          color: "DodgerBlue", // Default color
          // text: index,
          htmlContent: atlas.getImageTemplate("pin", 1.2),
          position: device.position || [defaultPosition],
        });

        map.markers.add(marker);
        markersRef.current.push({ deviceId: device.id, marker }); // Store markers

        // Event listener for clicking a marker
        map.events.add("click", marker, () => {
          setSelectedDeviceId(device.id);
        });
      });
    });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.dispose();
      }
    };
  }, [devices]);

  // Update the selected marker's color and map camera when the selected device changes
  useEffect(() => {
    if (selectedDeviceId !== null && mapInstanceRef.current) {
      markersRef.current.forEach(({ deviceId, marker }) => {
        marker.setOptions({
          color: deviceId === selectedDeviceId ? "Red" : "DodgerBlue",
        });
        if (deviceId === selectedDeviceId && listItemRefs.current[deviceId]) {
          listItemRefs.current[deviceId].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      });
    }
  }, [selectedDeviceId]);

  const handleDeviceToMapClick = useCallback(
    (id) => {
      setSelectedDeviceId(id);

      // Ensure the accordion is expanded when a device is clicked
      setExpanded(true);

      if (mapInstanceRef.current && devices.length > 1) {
        mapBoundBox(mapInstanceRef.current);
      }
    },
    [devices],
  );

  const renderDeviceList = (device) => {
    const chipColor = device.status === "Connected" ? "success" : "info";

    return (
      <React.Fragment key={device.id}>
        <Card
          sx={{
            backgroundColor:
              selectedDeviceId === device.id ? "rgba(0, 0, 0, .06)" : "inherit",
            border: "1px solid #2b600033",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  textDecoration: "none",
                }}
                key={device.id}
                component={Link}
                to={`projects/${device.orgId}/devices/${device.id}`}
              >
                <Typography variant="deviceName" component="h6">
                  {device.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {device.address}
                </Typography>
              </Box>

              {device.position?.length === 2 && (
                <Box
                  onClick={() => handleDeviceToMapClick(device.id)}
                  direction="column"
                  sx={{ alignItems: "center", cursor: "pointer" }} // Add cursor pointer for clickable effect
                >
                  <Typography color="textSecondary">Map</Typography>
                  <PlaceIcon
                    fontSize="large"
                    sx={{
                      color: selectedDeviceId === device.id ? "red" : "grey",
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box
              ref={(el) => (listItemRefs.current[device.id] = el)}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "auto auto",
                gap: "5px",
                gridTemplateAreas: `
                "a b"
                "d e"
              `,
                margin: "10px 0",
                alignItems: "center",
              }}
            >
              {/* Device Status */}
              <Box sx={{ gridArea: "a" }}>
                <Typography variant="body2">Unit Status:</Typography>
              </Box>
              <Box sx={{ gridArea: "b", width: "100px" }}>
                {device.status ? (
                  <Chip
                    label={device.status}
                    color={chipColor}
                    size="small"
                    sx={{ minWidth: "100px" }}
                  />
                ) : (
                  <Chip
                    label="Inactive"
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                  />
                )}
              </Box>

              {/* Flow Control */}
              <Box sx={{ gridArea: "d", width: "100px" }}>
                <Typography variant="body2">Flow Control:</Typography>
              </Box>
              <Box sx={{ gridArea: "e" }}>
                {device.valvePosition !== "N/A" ? (
                  <Chip
                    label={device.valvePosition}
                    color="primary"
                    size="small"
                    sx={{ minWidth: "100px", textTransform: "capitalize" }}
                  />
                ) : (
                  <Chip
                    label={device.valvePosition}
                    color="primary"
                    size="small"
                    sx={{ minWidth: "100px", textTransform: "capitalize" }}
                    variant="outlined"
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded); // Toggle the accordion map
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(60dvh - 150px)",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Stack>
        <Alert severity="error">{error}</Alert>
      </Stack>
    );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Accordion
				className="my-class"
          expanded={expanded}
          onChange={handleAccordionChange}
          sx={{
            boxShadow: "none",
            borderRadius: "10px!important",
						position:"sticky",
						top:"67px",
						zIndex:"60",
						background:"white",
						// paddingBottom:"20px",
						background:"#efefef",
						"&.my-class:after": { 
							content: "''",
        			display: "block",
							background:"white",
							width:"100%",
							height:"20px",
							position:"absolute",
							zIndex:"59"
						},
						
          }}
          // slotProps={{ heading: { component: 'h4' } }}
        >
          <AccordionSummary 
					sx={{ 
						// background:"#efefef", 
						// borderRadius: expanded ? "10px 10px 0 0" : "10px",
					}}
					expandIcon={<ExpandMoreIcon />}>
            Map
          </AccordionSummary>
          <AccordionDetails
					sx={{ 
						// background:"#efefef",
						// borderRadius:"0 0 10px 10px"
					}}
					>
            <Box
              ref={mapRef}
              sx={{ height: "calc(60dvh - 260px)", width: "100%"}}
            ></Box>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            padding: "27px 6px",
            // maxHeight: expanded ? "35dvh" : "calc(100dvh - 260px)",
            overflowY: "auto",
            // maxHeight: "50dvh",
          }}
        >
          {devices.length === 0 ? (
            <Box sx={{ textAlign: "center" }}>
              <Alert severity="error">No devices found</Alert>
            </Box>
          ) : (
            <List
              component="nav"
              aria-label="devices"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: 0,
              }}
            >
              {devices.map((device) => renderDeviceList(device))}
            </List>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AzureMapComponent;
