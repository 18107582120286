import WqmsError from "./WQMSError.mjs";

const REQ_TIMEOUT_MILLISECONDS = 20000;

async function fetchData(url) {
  let telemetry;

  try {
    //console.debug("Starting to send request now.");
    await fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate, br, zstd",
        "Accept-language": "en-US",
      },
      signal: AbortSignal.timeout(REQ_TIMEOUT_MILLISECONDS),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.debug("Got response now.");
        if (data.error) {
          throw new WqmsError(data.error, data.status);
        }

        telemetry = data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (err) {
    if (err.name === "TimeoutError") {
      console.error(
        `Timeout: fetch took more than ${REQ_TIMEOUT_MILLISECONDS / 1000} seconds to get the result!`,
        err.message,
      );
    } else if (err.name === "AbortError") {
      console.error(
        "Fetch aborted by user action (browser stop button, closing tab, etc.",
        err.message,
      );
    } else if (err.name === "TypeError") {
      console.error(
        "AbortSignal.timeout() method is probably not supported",
        err.message,
      );
    } else if (err.name === "WQMSError") {
      console.error("Backend Error", err.message);
    } else {
      // A network error, or some other problem.
      console.error(`Error: type: ${err.name}, message: ${err.message}`);
    }
  }

  return telemetry;
}

async function getTelemetryForTime({
  deviceId,
  user,
  isRealtime: rt = true,
  fromISODate,
  toISODate,
}) {
  const url = new URL(
    "https://rt-telemetry.azurewebsites.net/api/getTelemetry",
  );

  const params = new URLSearchParams({
    user,
    deviceId,
    fromISODate,
    toISODate,
    rt,
  });

  url.search = params.toString();

  return await fetchData(url);
}

async function getDeviceHierarchy(user) {
  const url = new URL(
    "https://rt-telemetry.azurewebsites.net/api/getDeviceHierarchy",
  );

  const params = new URLSearchParams({
    user,
  });

  url.search = params.toString();

  return await fetchData(url);
}

async function getRules(orgId) {
  const url = new URL("https://rt-telemetry.azurewebsites.net/api/getOrgRules");

  const params = new URLSearchParams({
    orgId,
  });

  url.search = params.toString();

  return await fetchData(url);
}
export default fetchData;
export { getTelemetryForTime, getDeviceHierarchy, getRules };
