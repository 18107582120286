import React, { useState, useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";

import AzureMapComponent from "../components/AzureLocationMap.mjs";

export default function Projects() {
  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);

  const [syncNeeded, setSyncNeeded] = useState(false);

  const performSync = function () {
    loadDeviceHierarchy();
  };

  function startLoadingData(event) {
    console.debug(`Received message from service worker:`, event?.data.msg);
    if (event?.data.msg === "activated") {
      setSyncNeeded(true);
    } else {
      console.error("Unhandled message from service worker: ", event?.data.msg);
    }
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.removeEventListener("message", startLoadingData);
      navigator.serviceWorker.addEventListener("message", startLoadingData);
    }
    //loadDeviceHierarchy();
  }, []);

  useEffect(() => {
    if (syncNeeded) {
      performSync();
    }

    setSyncNeeded(false);
  }, [syncNeeded]);

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!deviceHierarchy?.tree ? (
        <Box>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
          <Skeleton
            sx={{ marginTop: "0.2em" }}
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
        </Box>
      ) : (
        <AzureMapComponent />
      )}
    </Box>
  );
}
