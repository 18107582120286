import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { Container, Button, Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@emotion/react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  /* useMsal, */
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Header from "../components/Header";
import theme from "./Theme";
import { loginRequest } from "../authConfig";

/**
 * This purely layout component has a Header and Outlet under it to render subcomponents.
 */
export default function Dash({ instance }) {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  //const { instance } = useMsal();

  const queryParams = new URLSearchParams(window.location.search);
  const loginBypass =
    queryParams.get("loginBypass") ||
    window.sessionStorage.getItem("loginBypass");
  const bypassUser =
    queryParams.get("user") || window.sessionStorage.getItem("user");

  const updateUser = useStoreActions((actions) => actions.updateUser);

  const activeAccount = instance.getActiveAccount();
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  /* function LoginPageRequester() {
    useEffect(() => {
      instance
        .loginRedirect({
          ...loginRequest,
          prompt: "login",
        })
        .catch((error) => console.log(error));
    }, []);

    return <></>;
  } */

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "login",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    async function initSecurity() {
      await instance.initialize();
      await instance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            // Handle successful response
            updateUser(response?.account?.username);
            //console.info("Got response!", activeAccount);
            setIsLoggedIn(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (!loginBypass && !activeAccount) {
      initSecurity();
    } else if (!loginBypass && activeAccount) {
      updateUser(activeAccount?.username);
    } else {
      //login bypass
      updateUser(bypassUser || "amehta@renaissanceenv.com");
      window.sessionStorage.setItem("loginBypass", true);
      window.sessionStorage.setItem(
        "user",
        bypassUser || "amehta@renaissanceenv.com",
      );
    }
  }, [instance]);

  return (
    <MsalProvider instance={instance}>
      <CssBaseline />
      {loginBypass ? (
        <ThemeProvider theme={theme}>
          <Header />
          <Container>
            <Outlet />
          </Container>
        </ThemeProvider>
      ) : (
        <>
          <AuthenticatedTemplate>
            <ThemeProvider theme={theme}>
              <Header />
              <Container>
                <Outlet />
              </Container>
            </ThemeProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Box>
                <Button
                  className="signInButton"
                  onClick={handleRedirect}
                  variant="primary"
                >
                  Login
                </Button>
              </Box>
            </Box>
          </UnauthenticatedTemplate>
        </>
      )}
    </MsalProvider>
  );
}
