import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Box, Typography, Skeleton } from "@mui/material";

function Rule({ rule }) {
  return (
    <Box sx={{
			border:"1px solid #0000001a",
			borderRadius:"10px",
			minWidth:200,
			maxWidth:460,
			width:"100%",
			display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "space-around",
    flexWrap: "wrap",
    padding: "10px 20px"
		}}>
      <Box><Typography>{rule.displayName}</Typography></Box>
      <Box>
				<Box>
					<Typography>{rule.condition}</Typography>
				</Box>
				<Box>
					<Typography>{rule.severity}</Typography>
				</Box>
			</Box>
    </Box>
  );
}

function RuleList({ rules }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
				gap:"16px",
				marginTop:"20px"
      }}
    >
      {rules.map((rule) => (
        <Rule 
				key={rule.id} rule={rule} />
      ))}
    </Box>
  );
}

export default function Rules() {
  const getRules = useStoreActions((actions) => actions.rules.getRules);

  const alertRules = useStoreState((state) => state.rules.data);

  useEffect(() => {
    getRules();
  }, []);

  return (
    <Box component="section">
      {!alertRules?.length ? (
        <Skeleton variant="rounded" animation="wave" width={210} height={60} />
      ) : (
        <RuleList rules={alertRules} />
      )}
    </Box>
  );
}
