import React from "react";

export default function AboutUs() {
  return (
    <>
      <h3>Effusive (WQMS) v1.0</h3>
      <p>For more information or support please visit our web site:</p>
      <p>
        <a
          href="https://renaissanceenv.com/?page_id=30"
          rel="noreferrer"
          target="_blank"
        >
          https://renaissanceenv.com/
        </a>
      </p>
      <h5>Copyright: Renaissance Environmental Ltd.</h5>
      <h5>All rights reserverd.</h5>
    </>
  );
}
