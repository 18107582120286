import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { DateTime } from "luxon";

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  {
    field: "eventType",
    headerName: "Event Type",
    width: 150,
    editable: false,
  },
  {
    field: "startedAt",
    headerName: "Occured at",
    width: 180,
    valueFormatter: (value, row) => {
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "deviceName",
    headerName: "Device name",
    width: 150,
    editable: false,
  },
  {
    field: "eventValue",
    headerName: "Result",
    width: 120,
    editable: false,
  },

  /* {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  }, */
];

const rows = [
  {
    id: 1,
    eventType: "Connectivity",
    startedAt: "2024-06-21T18:12:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "connected",
  },
  {
    id: 2,
    eventType: "Alert Resolved",
    startedAt: "2024-06-21T18:13:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "Turbidity > 100",
  },
  {
    id: 3,
    eventType: "Connectivity",
    startedAt: "2024-06-21T18:14:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "connected",
  },
  {
    id: 4,
    eventType: "Valve Position",
    startedAt: "2024-06-21T18:15:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "open",
  },
  {
    id: 5,
    eventType: "Valve Position",
    startedAt: "2024-06-21T18:16:52.048Z",
    deviceName: "IoT Device 2 ",
    eventValue: "closed",
  },
];

export default function DataGridDemo() {
  return (
    <Box sx={{ marginTop:"16px", width: "100%" }}>
      <DataGrid
			sx={{
				borderRadius:"10px",
				borderColor:"#2b600033",
				"& .MuiDataGrid-row:nth-of-type(even)":{
					backgroundColor:"#00000012"
				},
				"& .MuiDataGrid-cell":{
					border:"none"
				},
				"& .MuiDataGrid-columnSeparator":{
					display:"none"
				},
				"& .MuiDataGrid-columnHeaderTitle":{
					fontWeight:"bold"
				},
				"& .MuiDataGrid-footerContainer":{
					background:"#2b600033",
					border:"#2b600033"
				},
				"& .MuiDataGrid-columnHeaders>div":{
					background:"#2b600033!important",
				}
			}}
				disableColumnMenu 
				disableColumnFilter 
				disableColumnSelector 
				disableColumnSorting
				columnHeaderHeight={36} 
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
